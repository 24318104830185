import axios from 'axios';
import VARS from './VARS'

const action = (slug, params, method) => {
    let data = '';
    
    if(method === undefined){
        method = 'GET';
    }

    if(method === 'GET'){
        let slugparams = '';
        for (const key in params) {
            if (Object.hasOwnProperty.call(params, key)) {
                const value = params[key];
                slugparams += 
                (slugparams.length === 0 ? '?': '')+
                (slugparams.length === 0 ? key: '&'+key)+
                '='+value;
            }
        }
        slug += slugparams;
        data = JSON.stringify([]);
    }else{
        data = JSON.stringify(params);
    }

    let config = {
        method: method,
        url: VARS.host+slug,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': VARS.Authorization, 
            'Content-Type': 'application/json',
            'Token': VARS.getToken()
        },
        data : data
    };
    return axios(config);
}

const file = (slug, form, callback) => {
    let config = {
        method: 'POST',
        url: VARS.host+slug,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': VARS.Authorization,
            'Token': VARS.getToken()
        },
        data : form,
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if(callback){
            callback(percentage);
          }
        }
    };
    return axios(config);
}

const $ = {
    external: () => {
        return axios;
    },
    post: (slug, params) => {
        return action(slug, params, 'POST');
    },
    get: (slug, params) => {
        return action(slug, params, 'GET');
    },
    upload: (slug, form, callback)=>{
        return file(slug, form, callback);
    },
    cache: async (slug) => {
        try {
            const response = await action(VARS.cache+slug, {}, 'GET');
            return await response.json();
        } catch (error) {
            
        }
    }
}

export default $;