import settings from "../cache/global/settings.json";
//import global from "../cache/global/global.json";
//import home from "../cache/home/home.json";
//import register from "../cache/register/register.json";
//import login from "../cache/login/login.json";
import recovery from "../cache/recovery/recovery.json"
import verification from "../cache/verification/verification.json";

//import publishboat from "../cache/publish/boat/publishboat.json";
//import publishservice from "../cache/publish/service/publishservice.json";
//import publishbank from "../cache/publish/bank/publishbank.json"
//import notfound from "../cache/notfound/notfound.json";
import boateditprice from "../cache/boat/edit/price/boateditprice.json";


import listservices from "../cache/service/list/listservices.json";
import listbookings from "../cache/bookings/list/listbookings.json";
import listfinances from "../cache/finances/list/listfinances.json";
import listpreferences from "../cache/preferences/list/listpreferences.json"
//import skipperpreferences from "../cache/preferences/skipper/preferences.json"

import request from "../cache/request/request.json"

//import aboutus from "../cache/aboutus/aboutus.json"
//import contact from "../cache/contact/contact.json"

const SERVER_CACHE = window.CacheData;

const Cache = {
    global: SERVER_CACHE?.global??{},
    settings: settings,
    home: SERVER_CACHE?.home??{},
    aboutus: SERVER_CACHE?.aboutus??{},
    contact: SERVER_CACHE?.contact??{},
    register: SERVER_CACHE?.register??{},
    login: SERVER_CACHE?.login??{},
    recovery: recovery,
    verification: verification,
    notfound: SERVER_CACHE?.notfound??{},
    publish: {
        boat: SERVER_CACHE?.publishboat??{},
        service: SERVER_CACHE?.publishservice??{},
        bank: SERVER_CACHE?.publishbank??{}
    },
    boateditprice: boateditprice,
    listservices: listservices,
    listbookings: listbookings,
    listfinances: listfinances,
    listpreferences: listpreferences,
    skipperpreferences: SERVER_CACHE?.preferences??{},
    skipper: {
        request: request
    }
};
export default Cache;