import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 512 512"
      >
        <path d="M406.65,486H98.77c-44.2,0-80.36-37-80.36-82.27V96.4c0-45.24,36.16-82.26,80.36-82.26H406.65c44.2,0,80.36,37,80.36,82.26V403.73C487,449,450.85,486,406.65,486" fill={PRIMARY_MAIN}/>
        <path d="M381.32,81.93c7.44,12.55-1.7,28.5-16.22,28.29,3-9.45-9-16.41-15.65-9.1-7.44-12.55,1.7-28.51,16.21-28.29-3,9.44,9,16.41,15.66,9.1M268.15,307.06q-12.67,24.35-25.34,48.69s-3.62,15,16.2,25.51c26.87,14.23,91.11,20,108.45,13.53l-4.8-9.39,42.26,5.88c-6.3,6-22.65,21.52-32.68,26.52l.75-8.37c-85.63,36.66-104.67-17.4-205.18,28.13C156.74,327.35,100.45,337.5,89.17,244.42l-6.83,4.83C81.63,238,86.8,216,88.81,207.5L115,241.4l-10.48.52c3.09,18.37,40.19,71.49,65.87,87.81,19,12.08,29.39,1.43,30.4,1.35l16.59-26.38L275,214.44c-11.57-10.6-2.7-26.42,7.37-21.39-6.5-9.85,1-22.8,10.39-18.12-6-9.1.14-28,15.73-13.76l6.16-9.79L265.85,123c-3.63-2.12-3.92-1.8-.25-8.2,2.51-4.36,7.68-4.95,12.42-3.36l51,17.08,9.94-15.83C315.75,83.54,350,43,382.35,61.83c31.55,18.35,15.62,67.23-20.44,63.5L353.25,142l42.29,37.81c6.73,6,4.46,10.53,1.64,15.45-2.25,3.93-3.88,1.88-5.49.94l-51.16-29.75-8.47,16.28c12.2,11.16,2.83,26.62-7.06,21.68,6.51,9.85-1,22.8-10.39,18.12,7.19,10.89-2.68,24.44-12.12,17-14.43,27.71-19.92,39.84-34.34,67.55" fill="#fff" fillRule="evenodd"/>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
