import { useContext } from 'react';
//
import { NooboxContent } from '../Noobox/NooboxContent';
// ----------------------------------------------------------------------

const Auth = () => {
  const context = useContext(NooboxContent);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default Auth;
