import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { NooboxProvider } from "./components/global/Context/Noobox/NooboxContent";
import { DrawerProvider } from "./components/global/Context/Drawer/DrawerContext";
import theme from "./theme/theme";

import "./index.css";
import {Ulaboat} from "./pages/routes";
import Alert from "./components/Alert";
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '../src/hooks/store';
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    {/*<React.StrictMode>*/}
      <BrowserRouter>
        <NooboxProvider>
          <DrawerProvider>
            <ReduxProvider store={store}>
              <Ulaboat/>
              <Alert/>
            </ReduxProvider>
          </DrawerProvider>
        </NooboxProvider>
      </BrowserRouter>
    {/*</React.StrictMode>*/}
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
