import { Suspense, lazy } from 'react';
import { Routes, Route, useLocation, useParams } from "react-router-dom";

import Auth from '../../components/global/Context/Auth/Auth';
import LoadingScreen from '../../components/LoadingScreen';

import VARS from '../../hooks/VARS';
import {PATH} from '../routes';

import { GoogleOAuthProvider } from '@react-oauth/google';

const loadable = (Component) => {
    return (props) => {
        const params = useParams();
        const location = useLocation();
        const searchParams = new URLSearchParams(location?.search??'');
        const paramsObject = Object.fromEntries(searchParams.entries());
        const { isAuthenticated, isInitialized } = Auth();

        if (!isInitialized) {
            return <LoadingScreen />;
        }
        return (
            <Suspense fallback={<LoadingScreen isDashboard={location?.pathname.includes('/dashboard')} />}>
                <Component {...props} pathname = {location?.pathname} isAuth = {isAuthenticated} request={params} get={paramsObject}/>
            </Suspense>
        );
    };
};



const AboutUsPage = loadable(lazy(() => import("../public/aboutus/AboutUsScreen")));
const ContactPage = loadable(lazy(() => import("../public/contact/ContactScreen")));

const DynamicPage = loadable(lazy(() => import("../public/pages/PagesScreen")));
const HomePage = loadable(lazy(() => import("../public/home/HomeScreen")));
const LoginPage = loadable(lazy(() => import('../public/login/LoginScreen')));
const RecoveryPage = loadable(lazy(() => import('../public/recovery/RecoveryScreen')));

const NotFoundPage = loadable(lazy(() => import('../public/404/NotFoundScreen')));

const VerificationPage = loadable(lazy(() => import('../public/verification/VerificationScreen')));

const RegisterPage = loadable(lazy(() => import('../public/register/RegisterScreen')));
const BoatPage = loadable(lazy(() => import('../public/boat/BoatViewScreen')));
const BoatsPage = loadable(lazy(() => import('../public/boats/BoatsScreen')));

const PublishPage = loadable(lazy(() => import('../public/publish/boat/BoatScreen')));

//const TProfilePage = loadable(lazy(() => import('../profile/Profile'))); 
const ProfilePage = loadable(lazy(() => import('../public/profile/ProfileScreen'))); 

//CheckOut
const CheckoutPage = loadable(lazy(() => import('../public/checkoutwizard/CheckoutWizardScreen')));
const OrderPage = loadable(lazy(() => import('../public/orders/OrdersScreen')));

//Estadisticas dueño
const StatisticsPage = loadable(lazy(() => import('../dashboard/statistics/StatisticsScreen')));
//Manager embarcaciones
const ListBoatsPage = loadable(lazy(() => import('../dashboard/manager/boats/list/ListBoatScreen')));
const EditBoatPage = loadable(lazy(() => import('../dashboard/manager/boats/edit/BoatEditScreen')));
const ReviewsBoatPage = loadable(lazy(() => import('../dashboard/manager/boats/reviews/BoatReviewsScreen')));
const AnalyticsBoatPage = loadable(lazy(() => import('../dashboard/manager/boats/analytics/BoatAnalyticsScreen')));
//Manager bookings
const ListBookingsPage = loadable(lazy(() => import('../dashboard/manager/bookings/list/ListBookingsScreen')));
const ViewBookingPage = loadable(lazy(() => import('../dashboard/manager/bookings/view/ViewBookingScreen')));
//Manager finanzas
const ListFinancesPage = loadable(lazy(() => import('../dashboard/manager/finances/list/ListFinancesScreen')));
//Manager servicios
const ListServicesPage = loadable(lazy(() => import('../dashboard/manager/services/list/ListServicesScreen')));
const EditServicePage = loadable(lazy(() => import('../dashboard/manager/services/edit/ServiceEditScreen')));
//Mensajes
const ChatPage = loadable(lazy(() => import('../dashboard/messages/chat/ChatScreen')));


//Skipper
const RegisterSkipperPage = loadable(lazy(() => import('../dashboard/skipper/request/RequestScreen')));
const PreferenceSkipperPage = loadable(lazy(() => import('../dashboard/skipper/preferences/PreferenceScreen')));
//Manager bookings skipper
const ListSkipperBookingsPage = loadable(lazy(() => import('../dashboard/skipper/bookings/list/ListBookingsScreen')));
const ViewSkipperBookingPage = loadable(lazy(() => import('../dashboard/skipper/bookings/view/ViewBookingScreen')));
//Manager finanzas
const ListFinancesSkipperPage = loadable(lazy(() => import('../dashboard/skipper/finances/list/ListFinancesScreen')));
//Manager reviews
const ReviewsSkipperPage = loadable(lazy(() => import('../dashboard/skipper/reviews/ReviewsScreen')));

//Usuarios
const EditProfilePage = loadable(lazy(() => import('../dashboard/profile/ProfileScreen')));
//Manager payments
const EditPaymentPage = loadable(lazy(() => import('../dashboard/payment/edit/PaymentEditScreen')));

//Manager bookings
const ListBookingsNonePage = loadable(lazy(() => import('../dashboard/bookings/list/ListBookingsScreen')));
const ViewBookingNonePage = loadable(lazy(() => import('../dashboard/bookings/view/ViewBookingScreen')));
const EditBookingNonePage = loadable(lazy(() => import('../dashboard/bookings/edit/EditBookingScreen')));

const ListNotificationsPage = loadable(lazy(()=> import('../dashboard/notifications/ListNotificationsScreen')))

const public_children = [
    {path: "home", element: <HomePage/> },
    {path: "login", element: <LoginPage/> },
    {path: "register", element: <RegisterPage/>},
    {path: "recovery", element: <RecoveryPage/>},
    {path: "publish", element: <PublishPage/>},
    {path: "boat", element: <BoatPage/>},
    {path: "boats", element: <BoatsPage/>},
    {path: "city", element: <BoatsPage/>},
    {path: "port", element: <BoatsPage/>},
    {path: "typed", element: <BoatsPage/>},
    {path: "checkout", element: <CheckoutPage/>},
    {path: "orders_public", element: <OrderPage/>},
    {path: "profile", element: <ProfilePage/>},
    //{path: "profilet", element: <TProfilePage/>},
    {path: "pages", element: <DynamicPage/>},
    {path: "aboutus", element: <AboutUsPage/>},
    {path: "contact", element: <ContactPage/>},
    {path: "verification", element: <VerificationPage/>},
    {path: "notifications", element: <ListNotificationsPage/>},
    {path: "all", element: <NotFoundPage/>},
    {path: "page404", element: <NotFoundPage/>}
];

const owner_children = [
    {path: "dashboard", element: <StatisticsPage/> },
    {path: "boats", element: <ListBoatsPage/> },
    {path: "boat_new", element: <EditBoatPage/> },
    {path: "boat_edit", element: <EditBoatPage/> },
    {path: "boat_edit_step", element: <EditBoatPage/> },
    {path: "boat_analytics", element: <AnalyticsBoatPage/> },
    {path: "boat_reviews", element: <ReviewsBoatPage/> },
    {path: "bookings", element: <ListBookingsPage/> },
    {path: "booking", element: <ViewBookingPage/> },
    {path: "finances", element: <ListFinancesPage/> },
    {path: "services", element: <ListServicesPage/> },
    {path: "service_new", element: <EditServicePage/> },
    {path: "service_edit", element: <EditServicePage/> },
    {path: "register_skipper", element: <RegisterSkipperPage/> }
];

const skipper_children = [
    {path: "preference_skipper", element: <PreferenceSkipperPage/> },
    {path: "skipper_bookings", element: <ListSkipperBookingsPage/> },
    {path: "skipper_booking", element: <ViewSkipperBookingPage/> },
    {path: "finances_skipper", element: <ListFinancesSkipperPage/> },
    {path: "skipper_reviews", element: <ReviewsSkipperPage/>}
];

const none_children = [
    {path: "messages", element: <ChatPage/> },
    {path: "message", element: <ChatPage/> },
    {path: "register_skipper", element: <RegisterSkipperPage/> },
    {path: "profile_edit", element: <EditProfilePage/>},
    {path: "profile_edit_step", element: <EditProfilePage/>},
    {path: "payment_new", element: <EditPaymentPage/> },
    {path: "payment_edit", element: <EditPaymentPage/> },
    {path: "bookings_none", element: <ListBookingsNonePage/> },
    {path: "booking_none", element: <ViewBookingNonePage/> },
    {path: "booking_none_edit", element: <EditBookingNonePage/> }
];

const App = [
    {
        section: 'public',
        children: public_children,
    },
    {
        section: 'owner',
        children: owner_children
    },
    {
        section: 'skipper',
        children: skipper_children
    },
    {
        section: 'none',
        children: none_children
    },
    {
        section: 'owner+skipper',
        children: owner_children.concat(skipper_children)
    }
];

export default function Ulaboat() {

    const RenderRoutes = () => {
        return <Routes>
            {
                App.map((Seccions, e)=>{
                    const ROUTE_PATH = PATH[Seccions?.section];
                    return Seccions?.children.map((Secction, j)=>{
                        return <Route key={j}
                        path={ROUTE_PATH[Secction.path]}
                        element={Secction.element}
                    />
                    })
                })
            }
        </Routes> 
    }

    const Render = () => {
        if(VARS?.google_enabled && VARS?.google_client_id!==""){
            return <GoogleOAuthProvider clientId={VARS?.google_client_id}>
                {RenderRoutes()}
            </GoogleOAuthProvider>;
        }

        return RenderRoutes();
    }

    return Render();
  }
