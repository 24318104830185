import React, { Component } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { NooboxContent } from "./global/Context/Noobox/NooboxContent";

const Alerts = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Alert extends Component {
  static contextType = NooboxContent;

  constructor(props) {
    super(props);
    this.state = {
      severity: "error",
      messages: "",
      alert: false
    };
  }

  onload() {
    const { alert, setAlert } = this.context;
    if(alert === null){
      setAlert(this.run);
    }
  }

  componentDidMount() {
    this.onload();
  }

  componentDidUpdate() {
    this.onload();                           
  }

  run = () => {
    return {
      err: (message) => {
        this.setState({
          severity: "error",
          messages: message,
          alert: true
        })
      },
      warning: (message) => {
        this.setState({
          severity: "warning",
          messages: message,
          alert: true
        })
      },
      success: (message) => {
        this.setState({
          severity: "success",
          messages: message,
          alert: true
        })
      }
    }
  }

  render() {
    return <Snackbar open={this.state.alert} autoHideDuration={6000}  onClose={()=>{this.setState({alert:false})}}>
      <Alerts onClose={()=>{this.setState({alert:false})}} severity={this.state.severity} sx={{ width: "100%" }}>
        {this.state.messages}
      </Alerts>
    </Snackbar>
  }
}
