import PropTypes from 'prop-types';
import { createContext, useState, useEffect } from 'react';
// hooks
import VARS from '../../../../hooks/VARS';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const initialState = {
  collapseClick: false,
  collapseHover: false,
  onToggleCollapse: () => {},
  onHoverEnter: () => {},
  onHoverLeave: () => {},
  collapseMapClick: false,
  collapseMapHover: false,
  onToggleMapCollapse: () => {},
  onHoverMapEnter: () => {},
  onHoverMapLeave: () => {},
};

const DrawerContext = createContext(initialState);

// ----------------------------------------------------------------------

DrawerProvider.propTypes = {
  children: PropTypes.node,
};

function DrawerProvider({ children }) {
  const isDesktop = VARS.useResponsive('up', 'lg');

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [openMap, setOpenMap] = useState(false);

  const [collapse, setCollapse] = useState({
    click: false,
    hover: false,
  });  
  
  const [collapseMap, setCollapseMap] = useState({
    click: false,
    hover: false,
  });

  useEffect(() => {
    if (!isDesktop) {
      setCollapse({
        click: false,
        hover: false,
      });
      setCollapseMap({
        click: false,
        hover: false,
      });
    }
  }, [isDesktop]);

  //Map
  const handleToggleMapCollapse = () => {
    setCollapseMap({ ...collapseMap, click: !collapseMap.click });
  };

  const handleHoverMapEnter = () => {
    if (collapseMap.click) {
      setCollapseMap({ ...collapseMap, hover: true });
    }
  };

  const handleHoverMapLeave = () => {
    setCollapseMap({ ...collapseMap, hover: false });
  };

  const handleMapClose = () => {
    setOpenMap(false)
  }

  const handleMapOpen = () => {
    setOpenMap(true);
  }

  //End Map
  const handleToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click });
  };

  const handleHoverEnter = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true });
    }
  };

  const handleHoverLeave = () => {
    setCollapse({ ...collapse, hover: false });
  };

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true);
  }


  return (
    <DrawerContext.Provider
      value={{
        drawerIsOpen: open,
        handleClose,
        handleOpen,
        isDesktop, theme,
        isCollapse: collapse.click && !collapse.hover,
        collapseClick: collapse.click,
        collapseHover: collapse.hover,
        onToggleCollapse: handleToggleCollapse,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave,


        drawerIsOpenMap: openMap,
        handleMapClose,
        handleMapOpen,
        isCollapseMap: collapseMap.click && !collapseMap.hover,
        collapseMapClick: collapseMap.click,
        collapseMapHover: collapseMap.hover,
        onToggleMapCollapse: handleToggleMapCollapse,
        onHoverMapEnter: handleHoverMapEnter,
        onHoverMapLeave: handleHoverMapLeave,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}

export { DrawerProvider, DrawerContext };
