const Public = {
    home: "/",
    login: "/login",
    register: "/register",
    recovery: "/recovery",
    verification: "/verification/:slug",
    publish: "/publish/boat",
    boat: "/boat/:slug",
    boats: "/boats",
    city: "/city/:slug",
    port: "/port/:slug",
    typed: "/boats/:slug",
    checkout: "/checkout/:slug",
    orders_public: "/payment-processed/:slug",
    profile: "/profile/:slug",
    profilet: "/profilet/:slug",
    pages: "/page/:slug",
    aboutus: "/nosotros",
    contact: "/contacto",
    notifications: "/dashboard/notifications",
    all: "*",
    page404: "/page-not-found"
}

const Owner = {
    dashboard: "/dashboard",
    //Embarcaciones
    boats: "/dashboard/boats",
    boat_new: "/dashboard/boat/new",
    boat_edit: "/dashboard/boat/edit/:slug",
    boat_edit_step: "/dashboard/boat/edit/:slug/:step",
    boat_analytics: "/dashboard/boat/analytics/:slug",
    boat_reviews: "/dashboard/boat/reviews/:slug",
    //Reservas
    bookings: "/dashboard/manager/bookings",
    booking: "/dashboard/manager/booking/:slug",
    //Finanzas
    finances: "/dashboard/manager/finances",
    //Servicios
    services: "/dashboard/services",
    service_new: "/dashboard/service/new",
    service_edit: "/dashboard/service/edit/:slug",
    //Mensajes
    messages: "/dashboard/messages",
    message: "/dashboard/messages/:slug",

    //Register Skipper
    register_skipper: "/dashboard/skipper/request"
}

const Skipper = {
    dashboard: "/dashboard",
    preference_skipper: "/dashboard/skipper/preferences",    
    //Reservas
    skipper_bookings: "/dashboard/skipper/bookings",
    skipper_booking: "/dashboard/skipper/booking/:slug",
    //Mensajes
    messages: "/dashboard/messages",
    message: "/dashboard/messages/:slug",
    //Pagos

    //Finanzas
    finances_skipper: "/dashboard/skipper/finances",
    //Reviews
    skipper_reviews: "/dashboard/skipper/reviews",

}

const None = {
    dashboard: "/dashboard",

    //Mensajes
    messages: "/dashboard/messages",
    message: "/dashboard/messages/:slug",

    //Reservas
    bookings_none: "/dashboard/bookings",
    booking_none: "/dashboard/booking/:slug",
    booking_none_edit: "/dashboard/booking/:slug/:mode",

    //Profile
    profile_edit: "/dashboard/accounts/edit",
    profile_edit_step: "/dashboard/accounts/edit/:step",

    payment_new: "/dashboard/accounts/payment/new",
    payment_edit: "/dashboard/accounts/payment/edit/:slug",
}


const PATH = {
    public: Public,
    owner: Owner,
    skipper: Skipper,
    none: None,
    'owner+skipper': {...Owner, ...Skipper},
    $: (route, params)=>{
        const list = {...Public, ...Owner, ...Skipper, ...None};
        let link = list[route]??false;
        if(link && params){
            for (const e in params) {
                link = (link.split(e)).join(params[e]);
            }            
        }
        return link?link:"";
    }
}

export default PATH;