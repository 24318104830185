import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../../hooks/store';
import $ from "../../hooks/$"
// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  participants: [],
  recipients: [],
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;

      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;

      state.conversations.byId = objFromArray(conversations);
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      if (conversation) {
        state.conversations.byId[conversation.id] = conversation;
        state.activeConversationId = conversation.id;
        if (!state.conversations.allIds.includes(conversation.id)) {
          state.conversations.allIds.push(conversation.id);
        }
      } else {
        state.activeConversationId = null;
      }
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const message = action.payload;
      state.conversations.byId[message.idconversation].messages.push(message);
    },

    markConversationAsReadSuccess(state, action) {
      const { idconversation } = action.payload;
      const conversation = state.conversations.byId[idconversation];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, onSendMessage, resetActiveConversation } = slice.actions;

// ----------------------------------------------------------------------

export function sent(idconversation, id, message, contentType, attachments, idaccount, idbusiness, to){
  const newMessage = {
    id,
    idconversation,
    idbusiness,
    body: message,
    contentType,
    attachments,
    createdAt: new Date(),
    idaccount,
  }
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
  
      if(newMessage.contentType === 'text'){
        const r = await $.post("messages/sent", {idbusiness: newMessage.idbusiness, to: to, body: newMessage.body});
        if(r?.data){
          if(r?.data[0]!==undefined){
            newMessage.id = r?.data[0]?.id;
          }
        }
      }else{
        const data = new FormData();
        data.append("idbusiness", newMessage.idbusiness);
        data.append("contentType", newMessage.contentType);
        data.append("to", to);
        
        let filecount = 0;
        
        for (const e in newMessage.attachments) {
          if(newMessage.attachments[e]?.name && newMessage.attachments[e]?.size){
            data.append("file_attachments_"+filecount, "file_"+filecount);
            data.append("file_"+filecount, newMessage.attachments[e]);
            filecount += 1;            
          }
  
        }
  
        data.append("list_attachments", filecount);
  
        const r = await $.upload("messages/sent", data);
        if(r?.data){
          if(r?.data[0]!==undefined){
            newMessage.id = r?.data[0]?.id;
            newMessage.body = r?.data[0]?.body;
          }
        }
      }
      
      dispatch(slice.actions.onSendMessage(newMessage));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
};

export function getContacts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await $.get('/api/chat/contacts');
      dispatch(slice.actions.getContactsSuccess(response.data.contacts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await $.post('conversations/all');
      dispatch(slice.actions.getConversationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversation(slug) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await $.post('conversations/by', {
        slug: slug
      });

      const conversation = response?.data[0]??false;
      dispatch(slice.actions.getConversationSuccess(conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(idconversation) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await $.post('conversations/mark-as-seen', {
        idconversation: idconversation,
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ idconversation }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await $.post('conversations/participants', {
        slug: conversationKey,
      });
      dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
