import { createTheme } from '@mui/material/styles';
import palette from './palette';
import shadows, { customShadows } from './shadows';

import componentsOverride from './overrides';

const theme = createTheme({
  palette: palette.light,// : palette.dark,
  shadows:shadows.light,// : shadows.dark,
  customShadows: customShadows.light// : customShadows.dark,
});

theme.components = componentsOverride(theme);

export default theme;
